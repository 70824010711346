import { createContext, useContext } from "react"
import { toast } from "react-hot-toast"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useLoginMutation, useRefreshTokenMutation } from "../redux/user/user.api"
import { addUser } from "../redux/user/user.slice"
import { useGetBusinessesMutation } from "../redux/business/business.api"
import { EncryptStorage } from "encrypt-storage"
import { safeSetToLocalStorage } from "../utils/localStorageUtils"

const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")

interface UserContextType {
  loginHandler: (username: string, password: string) => Promise<void>
  signOutHandler: () => void
  refreshTokenHandler: () => Promise<void>
}

interface ServerResponse {
  message?: string
  data?: {
    token: string
    firstName: string
    lastName: string
    licensing: boolean
    locationContacts: any[]
  }
}

const UserContext = createContext<UserContextType>({
  loginHandler: async () => {
    await Promise.resolve()
  },
  signOutHandler: () => undefined,
  refreshTokenHandler: async () => {
    await Promise.resolve()
  },
})

export const useUserContext = () => useContext(UserContext)

interface Props {
  children: JSX.Element
}

export const UserContextProvider = (props: Props) => {
  const [login] = useLoginMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [getBusinesses] = useGetBusinessesMutation()
  const [refreshTokenMutation] = useRefreshTokenMutation()

  const loginHandler = async (username: string, password: string) => {
    try {
      const res: ServerResponse = await login({
        username,
        password,
      }).unwrap() // Unwrap the result to throw an error if the request fails

      if (res.message !== undefined && res.message !== "") {
        toast.dismiss()
        toast.error(res.message)
      } else {
        localStorage.setItem("token", res?.data?.token ?? "")
        localStorage.setItem("firstName", res?.data?.firstName ?? "")
        safeSetToLocalStorage("lastName", res?.data?.lastName ?? "")
        // localStorage.setItem("lastName", res?.data?.lastName ?? "")
        localStorage.setItem("licensing", JSON.stringify(res?.data?.licensing) ?? "")
        dispatch(addUser(res.data))
        const businessesRes: any = await getBusinesses({})
        let locations = businessesRes?.data?.data?.allLocations ?? []

        locations = [...locations].sort((a: any, b: any) => {
          return Number(a.id) - Number(b.id)
        })

        storage.setItem("locations", JSON.stringify(locations))

        const cParam = sessionStorage.getItem("cParam")
        if (cParam !== null && cParam !== undefined) {
          // Redirect to purchase-fills with the "c" parameter
          navigate(`/licensing/purchase-fills?c=${cParam}`)
        } else if (
          businessesRes?.data?.data?.allLocations === null ||
          businessesRes?.data?.data?.allLocations === undefined
        ) {
          toast.dismiss()
          toast.error(
            "There are no locations assigned to the current user. Please contact support if the problem persists.",
            { duration: 7000 }
          )
          navigate("/")
        } else {
          // Redirect to the default page after successful login (e.g., homepage)
          navigate("/")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const signOutHandler = () => {
    // localStorage.clear()
    localStorage.removeItem("token")
    localStorage.removeItem("firstName")
    localStorage.removeItem("lastName")
    localStorage.removeItem("locations")
    localStorage.removeItem("cParam")
    localStorage.removeItem("licensing")
    dispatch(addUser(null))
    navigate("/login")
  }

  const refreshTokenHandler = async () => {
    const currentToken = localStorage.getItem("token")
    try {
      const res = await refreshTokenMutation(currentToken).unwrap()

      if (res?.token !== undefined) {
        localStorage.setItem("token", res.token)
      } else {
        signOutHandler()
      }
    } catch (error) {
      console.log("Error refreshing token:", error)
      signOutHandler()
    }
  }

  const value = {
    loginHandler,
    signOutHandler,
    refreshTokenHandler,
  }

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
}
