import { type Location } from "react-router-dom"

export const isPasswordResetPage = (location: Location) => {
  const params = new URLSearchParams(location.search)
  const token = params.get("token")

  if (token == null) {
    return false
  }

  return location.pathname.startsWith("/recover") || location.pathname.startsWith("/reset")
}
