import { type FormEvent, useState } from "react"
import { toast } from "react-hot-toast"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import { useUserContext } from "../../context/UserContext"
import logo from "../../assets/logo.svg"
import UsernameField from "../../components/Login/UsernameField"
import PasswordField from "../../components/Login/PasswordField"
import RememberMeCheckbox from "../../components/Login/RememberMeCheckbox"
import LoginButton from "../../components/Login/LoginButton"
// import { Link } from "react-router-dom"

export const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const data = useUserContext()

  const loginHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    if (!navigator.onLine) {
      toast.dismiss()
      toast.error("You are offline. Please check your internet connection and try again later")
      setLoading(false)
      return
    }

    if (username === "") {
      toast.dismiss()
      toast.error("Please enter a username")
      setLoading(false)
      return
    }

    if (password === "") {
      toast.dismiss()
      toast.error("Please enter a password")
      setLoading(false)
      return
    }

    if (rememberMe) {
      localStorage.setItem("username", username)
      localStorage.setItem("password", password)
      localStorage.setItem("rememberMe", JSON.stringify(true))
    } else {
      localStorage.removeItem("username")
      localStorage.removeItem("password")
      localStorage.setItem("rememberMe", JSON.stringify(false))
    }

    try {
      await data.loginHandler(username, password)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" className="w-48 mb-4" />
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault()
            loginHandler(event).catch((error) => {
              console.error("An error occurred during login:", error)
            })
          }}
          noValidate
          sx={{ mt: 1 }}
        >
          <UsernameField setUsername={setUsername} />
          <PasswordField setPassword={setPassword} />
          <RememberMeCheckbox setRememberMe={setRememberMe} />
          <LoginButton loading={loading} />
          {/* <Typography
            sx={{
              marginBottom: 2,
              cursor: "pointer",
              textAlign: "center",
            }}
            variant="body2"
          >
            <Link style={{ fontWeight: "bold", textDecoration: "underline" }} to={"/recover"}>
              Forgot Your Password?
            </Link>
          </Typography> */}
        </Box>
        <Typography variant="body2">v{process.env.REACT_APP_VERSION_NUMBER}</Typography>
      </Box>
    </Container>
  )
}
