import * as Sentry from "@sentry/react"

enum EnvironmentType {
  Development = "DEVELOPMENT",
  QA = "QA",
  Production = "PRODUCTION",
  Staging = "STAGING",
  Local = "LOCAL",
}

interface SentryConfig {
  environment: string
  tracePropagationTargets: string[]
  tracesSampleRate: number
  replaysSessionSampleRate: number
  replaysOnErrorSampleRate: number
}

const environmentConfigurations: Record<EnvironmentType, SentryConfig> = {
  [EnvironmentType.Development]: {
    environment: EnvironmentType.Development,
    tracePropagationTargets: ["localhost", "https://devvgtc.pongstudios.ca"],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  },
  [EnvironmentType.QA]: {
    environment: EnvironmentType.QA,
    tracePropagationTargets: ["localhost", "https://qavgtc.pongstudios.ca"],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
  [EnvironmentType.Production]: {
    environment: EnvironmentType.Production,
    tracePropagationTargets: ["localhost", "https://vgtcentral.com"],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  },
  [EnvironmentType.Staging]: {
    environment: EnvironmentType.Staging,
    tracePropagationTargets: ["localhost", "https://stagingvgtc.pongstudios.ca"],
    tracesSampleRate: 0.75,
    replaysSessionSampleRate: 0.15,
    replaysOnErrorSampleRate: 1.0,
  },
  [EnvironmentType.Local]: {
    environment: EnvironmentType.Local,
    tracePropagationTargets: ["localhost"],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
}

function getSentryConfig(): SentryConfig {
  const deploymentTarget = process.env.REACT_APP_SENTRY_ENVIRONMENT as EnvironmentType
  return environmentConfigurations[deploymentTarget] !== null
    ? environmentConfigurations[deploymentTarget]
    : environmentConfigurations[EnvironmentType.Development]
}

const config = getSentryConfig()

Sentry.init({
  release: "vgtCentral v" + String(process.env.REACT_APP_VERSION_NUMBER),
  dsn: process.env.REACT_APP_SENTRY_DEV_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: config.environment,
  tracesSampleRate: config.tracesSampleRate,
  tracePropagationTargets: config.tracePropagationTargets,
  replaysSessionSampleRate: config.replaysSessionSampleRate,
  replaysOnErrorSampleRate: config.replaysOnErrorSampleRate,
})
