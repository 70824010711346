import { Email } from "@mui/icons-material"
import {
  Box,
  TextField,
  InputAdornment,
  Icon,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material"

interface PasswordResetRequestProps {
  loading: boolean
  email: string
  emailError: string
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const PasswordResetRequest = ({
  loading,
  email,
  emailError,
  handleEmailChange,
}: PasswordResetRequestProps) => {
  const disableResetButton = loading || emailError !== "" || email === ""
  return (
    <>
      <Typography sx={{ marginBottom: 1 }} variant="body1">
        We will send a confirmation email to this address.
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
        error={emailError !== ""}
        helperText={emailError ?? ""}
        autoFocus
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ margin: "0px -12px 0px 0px", padding: "8px" }}>
                <Icon component={Email} />
              </Box>
            </InputAdornment>
          ),
        }}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={disableResetButton}
        sx={{
          mt: emailError !== "" ? 0 : "23px",
          mb: 2,
          position: "relative",
        }}
      >
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        Reset Password
      </Button>
    </>
  )
}
